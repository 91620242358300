import {Color, BufferGeometry, Float32BufferAttribute, LineBasicMaterial, LineSegments} from 'three';

// Based on Source at https://github.com/mrdoob/three.js/blob/master/src/helpers/GridHelper.js
class BuildPlateGridHelper extends LineSegments {
  constructor(width: number = 250, height: number = 250) {
    // Create colors

    const lineColor = new Color(0x888888);

    const divisionsWidth = width / 10;
    const divisionsHeight = height / 10;

    // Calculate steps and half sizes
    const stepWidth = width / divisionsWidth;
    const stepHeight = height / divisionsHeight;
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const vertices: number[] = [];
    const colors: number[] = [];

    // Horizontal lines (parallel to the X-axis)
    for (let i = 0, j = 0, k = -halfHeight; i <= divisionsHeight; i++, k += stepHeight) {
      // From (-halfWidth, 0, k) to (halfWidth, 0, k)
      vertices.push(-halfWidth, 0, k, halfWidth, 0, k);

      lineColor.toArray(colors, j);
      j += 3;
      lineColor.toArray(colors, j);
      j += 3;
    }

    // Vertical lines (parallel to the Z-axis)
    for (let i = 0, j = colors.length, k = -halfWidth; i <= divisionsWidth; i++, k += stepWidth) {
      // From (k, 0, -halfHeight) to (k, 0, halfHeight)
      vertices.push(k, 0, -halfHeight, k, 0, halfHeight);

      lineColor.toArray(colors, j);
      j += 3;
      lineColor.toArray(colors, j);
      j += 3;
    }

    // Create geometry and add attributes
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));

    // Create material
    const material = new LineBasicMaterial({vertexColors: true, toneMapped: false});

    // Call parent class constructor
    super(geometry, material);

    this.type = 'GridHelper';
  }

  dispose(): void {
    this.geometry.dispose();
    // TS thinks materials is a list but it isint
    //@ts-ignore
    this.material.dispose();
  }
}

export {BuildPlateGridHelper as CustomGridHelper};
