import {OrbitControls} from '@kibou/three-orbitcontrols-ts';

import {
  AxesHelper,
  Color,
  Group,
  PerspectiveCamera,
  Plane,
  PlaneHelper,
  Scene,
  WebGLRenderer,
  PointLight,
  Vector3,
  BoxGeometry,
  WireframeGeometry,
  Material,
  LineSegments,
  Matrix4,
} from 'three';
import {BoundingBox, initialParams} from '../Base3DViewport';

import {EVENTS} from 'three-freeform-controls';
import {TransformHelper} from '../helpers/transformHelper';
import {debounce} from 'lodash';
import {CustomGridHelper} from '../helpers/BuildPlateGridHelper';

export const initialiseScene = (
  scene: Scene,
  camera: PerspectiveCamera,
  setControls: (controls: OrbitControls) => void,
  renderer: WebGLRenderer,
  sceneGroup: Group,
  clippingPlane: Plane,
  updateTransforms?: (tf: Matrix4) => void,
  plateSize?: [number, number]
) => {
  // Initialize ThreeJS viewport
  scene.background = new Color(initialParams.backgroundColour);

  // Light emitting from the camera in all directions (lights up the users point of view)
  const pointLight = new PointLight(0xffffff, 0.9);
  pointLight.position.set(0, 0, 1);
  camera.add(pointLight);

  scene.add(camera);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.zoomSpeed = 2;
  setControls(controls);

  // Add part models to scene
  scene.add(sceneGroup);

  const buildPlateSize = plateSize ? Math.max(plateSize[0], plateSize[1]) : 250;

  // Initialise helper models and add to scene
  const axesHelper = new AxesHelper(2 * buildPlateSize);
  axesHelper.name = 'axesHelper';
  axesHelper.visible = false;

  const gridHelper = new CustomGridHelper(plateSize ? plateSize[0] : 250, plateSize ? plateSize[1] : 250);
  gridHelper.name = 'gridHelper';
  gridHelper.visible = true;

  const planeHelper = new PlaneHelper(clippingPlane, buildPlateSize);
  planeHelper.name = 'planeHelper';
  planeHelper.visible = false;

  const helpers = new Group();
  helpers.name = 'helpers';
  helpers.add(axesHelper, gridHelper, planeHelper);
  helpers.visible = false; // Hide until first model is selected
  scene.add(helpers);

  const transformHelper = new TransformHelper(camera, renderer.domElement, 12, 2, 0.5, 4);

  transformHelper.name = 'rotationHelper';

  transformHelper.listen(EVENTS.DRAG_START, () => {
    controls.enabled = false;
  });

  transformHelper.listen(EVENTS.DRAG_STOP, () => {
    controls.enabled = true;
    setTimeout(() => renderer.render(scene, camera), 100);
  });

  transformHelper.listen(EVENTS.DRAG, (object) => {
    renderer.render(scene, camera);
    if (object && updateTransforms) {
      updateTransforms(object.matrix.transpose());
    }
  });

  scene.add(transformHelper);
};

// Some rough working for these calculations are available at
// smb://aastore.local/aastore/02 - Documentation/05 - Development Documentation [internal]/Webapp 3D viewport initial camera position.pdf
// Basically this works out how far the camera has to be along a line angled
// 30 degrees below the horizontal passing through the bounding box's center
// to view the entire bounding box.
export const calcCameraResetPosition = (
  bounds: BoundingBox,
  camera: PerspectiveCamera,
  transformHelperEnabled?: boolean
) => {
  const minBounds = new Vector3(...Object.values(bounds.min));
  const dimensions = new Vector3(...Object.values(bounds.dimensions));
  const center = minBounds.clone().add(dimensions.clone().divideScalar(2));

  // The Z dimension can be negative because of some coordinate system change weirdness.
  const dimensionsAbs = new Vector3(...Object.values(bounds.dimensions).map(Math.abs));

  const fov = camera.fov * (Math.PI / 180) * 0.8; // Reduce FOV to 80% to add a bit of padding
  const cameraPitch = Math.PI / 6; // Angle down from horizontal

  const boundsYZLength = dimensionsAbs.clone().setX(0).length();

  const minCameraDistToFitVertically = (() => {
    const boundsYZAngle = Math.atan2(dimensionsAbs.y, dimensionsAbs.z);

    const angle1 = fov / 2;
    const angle2 = cameraPitch + boundsYZAngle;
    const angle3 = Math.PI - angle1 - angle2;

    return ((boundsYZLength / 2) * Math.sin(angle3)) / Math.sin(angle1);
  })();

  const minCameraDistToFitHorizontally = (() => {
    const fovWidth = fov * camera.aspect;
    const distToBoundsEdge = dimensionsAbs.x / 2 / Math.tan(fovWidth / 2);
    return distToBoundsEdge + boundsYZLength / 2;
  })();

  let cameraDist = Math.max(minCameraDistToFitVertically, minCameraDistToFitHorizontally);
  cameraDist = transformHelperEnabled ? cameraDist * 1.5 : cameraDist;

  const cameraOffset = new Vector3(0, cameraDist * Math.sin(cameraPitch), cameraDist * Math.cos(cameraPitch));
  const cameraPos = center.clone().add(cameraOffset);

  return [cameraPos, center];
};
export const resetCamera = (
  bounds: BoundingBox,
  camera: PerspectiveCamera,
  controls: OrbitControls | null,
  renderScene: () => void,
  transformHelperEnabled?: boolean
) => {
  const [cameraPos, center] = calcCameraResetPosition(bounds, camera, transformHelperEnabled);

  camera.position.copy(cameraPos);
  camera.lookAt(center);
  controls?.target.copy(center);

  renderScene();
};

export const onBoundsChange = (
  showBoundingBox: boolean,
  sceneBounds: BoundingBox | null,
  resetCamera: (bounds: BoundingBox) => void,
  scene: Scene
) => {
  if (sceneBounds == null) {
    // No bounds have been supplied yet, nothing to update
    return;
  }

  const boundsOffset = (sceneBounds.min as Vector3)
    .clone()
    .add((sceneBounds.dimensions as Vector3).clone().divideScalar(2));
  const boundsGeometry = new BoxGeometry(sceneBounds.dimensions.x, sceneBounds.dimensions.y, sceneBounds.dimensions.z);
  const boundsWireframe = new WireframeGeometry(boundsGeometry);
  const boundsLines = new LineSegments(boundsWireframe);
  (boundsLines.material as Material).depthTest = false;
  (boundsLines.material as Material).opacity = 0.25;
  (boundsLines.material as Material).transparent = true;
  boundsLines.position.copy(boundsOffset);
  boundsLines.name = 'boundsLines';
  boundsLines.visible = showBoundingBox;

  // Remove previous bounds from scene, if present
  const previousBounds = scene.getObjectByName('boundsLines');
  if (previousBounds) scene.remove(previousBounds);

  scene.add(boundsLines);

  resetCamera(sceneBounds);
};

// Existing fullscreen on Firefox causes this function to be called initially with a width
// which is fullscreen and a height which is not. It is then called again correctly, but by
// for some reason firefox doesn't rerender it (even with changes successfully applied).
// Debouncing this function solves that issue.
export const resizeViewport = debounce(
  (
    camera: PerspectiveCamera,
    renderer: WebGLRenderer,
    renderScene: () => void,
    stageWidth: number,
    stageHeight: number
  ) => {
    if (!renderer) return;

    if (camera) {
      camera.aspect = stageWidth / stageHeight;
      if (camera.view) {
        camera.view.width = stageWidth;
        camera.view.height = stageHeight;
      }
      camera.updateProjectionMatrix();
    }

    renderer.setSize(stageWidth, stageHeight);
    renderScene();
  },
  100
);
