import React, {useState} from 'react';
import {Button, Grid, FormControlLabel, Checkbox, Typography, Tooltip, Box} from '@material-ui/core';
import {Add, Info} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import {DEFAULT_REPORT_CONFIGURATION, IBuildReportConfiguration} from '@common/api/models/builds/data/IBuildReport';
import {IBuild} from '@common/api/models/builds/IBuild';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {GenericDialog} from '../../components/molecules/DialogButton';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildReportActions from '../../store/actions/buildReportActions';
import SearchAndSelectBuild from '../../components/molecules/Selector/SearchAndSelectBuild';
import {COMPLETED_BUILD_FILTER} from '../shared/IndexPage/filterSchemas/buildReport';
import SearchAndSelect from '../../components/molecules/Selector/SearchAndSelect';
import {partsAllGET} from '../../api/ajax/parts';
import inflection from 'inflection';

function CreateBuildReportButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedBuild, setSelectedBuild] = useState<IBuild | null>(null);
  const [selectedParts, setSelectedParts] = useState<IPartGETResponse[]>();
  const [reportConfiguration, setReportConfiguration] =
    useState<IBuildReportConfiguration>(DEFAULT_REPORT_CONFIGURATION);

  const asyncDispatch = useAsyncDispatch();

  const handleCreateReport = async () => {
    if (!selectedBuild) return;
    // Unchecked include all parts, but no parts selected
    if (!!selectedParts && reportConfiguration.includedParts === null) return;

    setCreating(true);

    const success = await asyncDispatch(
      buildReportActions.createBuildReport({
        buildUuid: selectedBuild.uuid,
        reportConfiguration: {
          ...reportConfiguration,
          includedParts: reportConfiguration.includedParts === null ? null : selectedParts!.map((part) => part.uuid),
        },
      })
    );

    if (success) onClose();
    else setCreating(false);
  };

  const onClose = () => {
    setSelectedBuild(null);
    setSelectedParts(undefined);
    setReportConfiguration(DEFAULT_REPORT_CONFIGURATION);
    setCreating(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)}>
        <Add />
        New Report
      </Button>
      <GenericDialog
        title="New Build Report"
        maxWidth="xs"
        content={
          <CreateReportDialog
            selectedBuild={selectedBuild}
            setSelectedBuild={setSelectedBuild}
            selectedParts={selectedParts}
            setSelectedParts={setSelectedParts}
            reportConfiguration={reportConfiguration}
            setReportConfiguration={setReportConfiguration}
          />
        }
        isOpen={!!isModalOpen}
        closeDialog={onClose}
        onSuccess={handleCreateReport}
        confirmText="Create"
        confirmDisabled={!selectedBuild || (reportConfiguration.includedParts !== null && !selectedParts?.length)}
        requestInProgress={creating}
      />
    </>
  );
}

export default CreateBuildReportButton;

function CreateReportDialog({
  selectedBuild,
  setSelectedBuild,
  selectedParts,
  setSelectedParts,
  reportConfiguration,
  setReportConfiguration,
}: {
  selectedBuild: IBuild | null;
  setSelectedBuild: (newBuild: IBuild | null) => void;
  selectedParts: IPartGETResponse[] | undefined;
  setSelectedParts: (newParts: IPartGETResponse[] | undefined) => void;
  reportConfiguration: IBuildReportConfiguration;
  setReportConfiguration: React.Dispatch<React.SetStateAction<IBuildReportConfiguration>>;
}) {
  const fetchSearch = async (search: string): Promise<IPartGETResponse[]> => {
    const res = await partsAllGET({
      buildUuid: selectedBuild!.uuid,
      uuid: {notIn: selectedParts?.map((part) => part.uuid)},
      ...(!!search ? {name: {like: search}} : {}),
      take: 50,
    });

    if (res.success) return res.data;
    else return [];
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info" style={{marginBottom: '12px'}}>
          Build reports are only available for completed builds with at least 1 valid slice file and no slice files
          pending verification.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={{marginBottom: '6px'}}>
          Build
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SearchAndSelectBuild
          selectedBuild={selectedBuild}
          onBuildSelected={(build: IBuild | null) => {
            setSelectedBuild(build);
            setSelectedParts(undefined);
            setReportConfiguration((config) => ({
              ...config,
              includedParts: config.includedParts === null ? null : [],
            }));
          }}
          filters={{
            ...COMPLETED_BUILD_FILTER,
            hasReport: false,
            hasSliceData: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={{marginTop: '12px'}}>
          Report Sections
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <ReportCheckbox
            checked={reportConfiguration.includeCoverPage}
            onChange={() =>
              setReportConfiguration((config) => ({
                ...config,
                includeCoverPage: !config.includeCoverPage,
              }))
            }
            label="Cover page"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <ReportCheckbox
            checked={reportConfiguration.includeToc}
            onChange={() =>
              setReportConfiguration((config) => ({
                ...config,
                includeToc: !config.includeToc,
              }))
            }
            label="Table of contents"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <ReportCheckbox
            checked={reportConfiguration.includeOverallDefects}
            onChange={() =>
              setReportConfiguration((config) => ({
                ...config,
                includeOverallDefects: !config.includeOverallDefects,
              }))
            }
            label="Defects summary"
          />
          <Tooltip
            title={
              <Typography>
                If selected, the report will include a section detailing defects for the overall build with statistics
                and charts.
              </Typography>
            }
          >
            <Info color="action" style={{height: '22px'}} />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <ReportCheckbox
            label="Part defects"
            checked={reportConfiguration.includePartDefects}
            onChange={() =>
              setReportConfiguration((config) => ({
                ...config,
                includePartDefects: !config.includePartDefects,
              }))
            }
          />
          <Tooltip
            title={
              <Typography>
                If selected, each part section will include a sub-section detailing defects for the part with statistics
                and charts.
              </Typography>
            }
          >
            <Info color="action" style={{height: '22px'}} />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <ReportCheckbox
            label="Include all parts"
            checked={reportConfiguration.includedParts === null}
            onChange={() =>
              setReportConfiguration((config) => ({
                ...config,
                includedParts: config.includedParts === null ? [] : null,
              }))
            }
          />
          <Tooltip
            title={<Typography>Uncheck this box to select individual parts to include in the report.</Typography>}
          >
            <Info color="action" style={{height: '22px'}} />
          </Tooltip>
        </Box>
      </Grid>
      {reportConfiguration.includedParts !== null && (
        <Grid item xs={12}>
          <SearchAndSelect
            selected={selectedParts || []}
            setSelected={setSelectedParts}
            getSuggestionValue={(resource) => (resource as any).name}
            isSelected={(resource) => !!selectedParts?.find((part) => part.uuid === (resource as any).uuid)}
            fetchFunction={fetchSearch}
            label="Parts to include"
            disabled={!selectedBuild}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <ReportCheckbox
            checked={reportConfiguration.includeBuildPhoto}
            onChange={() =>
              setReportConfiguration((config) => ({
                ...config,
                includeBuildPhoto: !config.includeBuildPhoto,
              }))
            }
            label="Include Build Photo"
          />
          <Tooltip
            title={
              <Typography>
                If selected, the selected photo from Build Photos will be included into the report
              </Typography>
            }
          >
            <Info color="action" style={{height: '22px'}} />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <ReportCheckbox
            checked={reportConfiguration.includeGlossary}
            onChange={() =>
              setReportConfiguration((config) => ({
                ...config,
                includeGlossary: !config.includeGlossary,
              }))
            }
            label="Glossary"
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function ReportCheckbox({checked, onChange, label}: {checked: boolean; onChange: () => void; label: string}) {
  return (
    <FormControlLabel
      control={<Checkbox color="primary" checked={checked} onChange={onChange} name={inflection.underscore(label)} />}
      label={label}
      style={{marginRight: '6px'}}
    />
  );
}
